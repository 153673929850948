import React from "react";
import styled from "styled-components";

import Header from "../Components/Header/Header";
import HeroSection from "../Components/Home/HeroSection/HeroSection";
import HowToUse from "../Components/HowToUse/HowToUse";
import Fees from "../Components/Home/Fees/Fees";
const Wrapper = styled.div``;

const Home = () => {
  return (
    <Wrapper>
      <Header button />
      <HeroSection />
      <HowToUse />
      <Fees />
    </Wrapper>
  );
};

export default Home;
