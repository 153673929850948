import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  padding-top: 110px;
  .logo {
    width: 127px;
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    background: -webkit-linear-gradient(45deg, #36a693, #1d6559);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;
    /* or 100% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: #ffffff;
    text-align: center;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 125px;
  }
  @media only screen and (max-width: 767px) {
    .logo {
      width: 80px;
    }
    .title {
      font-size: 35px;
    }
    .text {
      font-size: 16px;
    }
  }
`;

const HeroSection = () => {
  return (
    <Wrapper>
      <Col
        xs={11}
        md={9}
        lg={8}
        xl={7}
        xxl={6}
        className="mx-auto d-flex justify-content-center align-items-center flex-column"
      >
        <div className="d-flex ">
          <img src="./images/secondLogo.png" alt="#" className="logo" />
          <h2 className="title">Distribution</h2>
        </div>
        <p className="text py-5">
          Dbooks Distribution tool allows you to batch send ETH, ERC20, BNB,
          and BEP20 tokens to multiple wallet addresses from a CSV file or
          TXT file.
        </p>
      </Col>
    </Wrapper>
  );
};

export default HeroSection;
