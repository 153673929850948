import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Link } from "react-router-dom";
import { parse } from "papaparse";
import { CSVLink } from "react-csv";
import { useRef } from "react";

const Wrapper = styled.div`
  padding-top: 110px;

  .hero-button {
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 6px 20px;
  }
  .hero-subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #fafafa;
  }
  .my-select {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;
    line-height: 22px;
    line-height: 100%;
    color: #36a693;
    position: relative;
  }
  .my-select-box {
    border-radius: 10px;
  }
  .dropdown-container {
    position: absolute;
    top: 35px;
    width: 100%;
    background: #060606;
    border-radius: 10px;
  }
  .dropdown-items:hover {
    background: var(--black);
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
  }

  .tokenAndDecimal-label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
  .tokenAndDecimal {
    background: var(--black);
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;

    color: var(--secondary-color);
    border-radius: 10px;
    color: #36a693;
    border: none;
    outline: none;
  }
  .dragAnddrop-container {
    height: 228px;

    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #fafafa;
  }
  .dragAndDropText {
    line-height: 22px;
    cursor: text;
  }
  .insert-manualy {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #36a693;
    display: block;
  }
  .approvedButton {
    border: none;
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;

    padding: 9px 22px;
  }

  .disableButton {
    border: none;
    background: linear-gradient(92.81deg,#6f968f -0.16%,#3e4c49 99.84%);
    border-radius: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #fafafa;
    padding: 9px 22px;
    cursor:pointer;
  }

  .download-sample {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #fafafa;
  }
  .file-type {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;

    color: #36a693;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
  }

  .duplicate-btn {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;

    color: #36a693;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    padding: 10px 15px;
    font-weight: 700;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(25 25 25);
}

  .approve-container {
    padding-top: 50px;
  }
  .text-area {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    color: var(--subtitle-color);
    resize: none;
  }
  .file-upload {
    width: 100%;
    height: 100%;
  }

  .t-wrapper::before {
    text-transform: uppercase;
    left: 0px;
    position: absolute;
    top: -18px;
    font-size: 16px;
    height: 200px;
  }
  
  .my-num-active,
  .my-num-wrapper,
  .my-num-line {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    vertical-align: middle;
    list-style: none;
  }
  
  .my-num-active {
    display: inline-block;
    padding: 0.625em;
    width: calc(100% - 3em);
    height: 100%;
    font-size: 1em;
    line-height: 1.5;
    font-family: "Roboto Mono",monospace;
    word-break: break-all;
    border: 1px solid #319e8b;
    background-color: #060606;
    resize: none;
    overflow-wrap: normal;
    overflow-x: auto;
    white-space: pre;
    color: #fff;
  }
  
  .my-num-wrapper {
    width: 3em;
    padding: 0.6875em 0.3125em 2.1875em;
    height: 100%;
    word-break: break-all;
    overflow: hidden;
    display: inline-block;
    counter-reset: line;
  }
  
  .my-num-line {
    width: 100%;
    display: block;
    text-align: right;
    line-height: 1.5;
    font-size: 1em;
    color: #aeaeae;
  }
  
  .my-num-line::before {
    counter-increment: line;
    content: counter(line);
    font-size: 1em;
    user-select: none;
  }

  @media only screen and (max-width: 767px) {
    .approve-container {
      padding-top: 30px;
    }
  }
`;

const ApprovedAndSend = () => {
  const text = useRef();

  const [dropdown, setDropdown] = useState(false);
  const [textarea, setTextArea] = useState(false);
  const [myfile, setMyfile] = useState("");

  const [value, setValue] = useState("");

  const tokenList = [
    "Token name (TOKEN)1",
    "Token name (TOKEN)2",
    "Token name (TOKEN)3",
    "Token name (TOKEN)4",
  ];
  const fileUploader = (e) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      setMyfile(event.target.result); // the CSV content as string
    };
    reader.readAsText(e.dataTransfer.files[0]);
  };
  const secondFileUploader = (e) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      setMyfile(event.target.result); // the CSV content as string
    };
    reader.readAsText(e.target.files[0]);
  };
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("myInput").value], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const csvReport = {
    filename: "myfile.csv",
    header: "",
    data: myfile,
  };

  return (
    <Wrapper>
      <Col
        xs={11}
        md={9}
        lg={8}
        xl={7}
        xxl={6}
        className="mx-auto hero-section-wrapper px-3 py-4"
      >
        <p className="m-0 hero-subtitle text-center">
          Login with your wallet to get started
        </p>
        <Link to="/" className="d-flex justify-content-center my-2">
          <button className="hero-button">Connect wallet</button>
        </Link>

        <Row>
          <Col sm={9} md={9} lg={10}>
            <div>
              <label className="tokenAndDecimal-label px-2">Token</label>
              <div className="my-select ">
                <div
                  className="my-select-box d-flex justify-content-between align-items-center "
                  onClick={() => setDropdown((prev) => !prev)}
                >
                  <p className="m-0 p-2 pt-3">
                    {value ? value : "Token name (TOKEN)"}
                  </p>
                  {dropdown ? (
                    <BiChevronUp style={{ cursor: "pointer" }} />
                  ) : (
                    <BiChevronDown style={{ cursor: "pointer" }} />
                  )}
                </div>
                {dropdown && (
                  <div className="dropdown-container py-2">
                    {tokenList.map((el, i) => (
                      <p
                        className="m-0 py-2  px-2 dropdown-items"
                        key={i}
                        onClick={() => {
                          setValue(el);
                          setDropdown((prev) => !prev);
                        }}
                      >
                        {el}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col sm={3} md={3} lg={2}>
            <div>
              <label className="tokenAndDecimal-label px-2">Decimal</label>
              <input
                type="text"
                className="tokenAndDecimal w-100 py-1 pt-2 px-2"
                placeholder="0"
              />
            </div>
          </Col>
        </Row>
        <div className="py-4">
          <div
            className="dragAnddrop-container d-flex justify-content-center align-items-center"
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              Array.from(e.dataTransfer.files).forEach(async (file) => {
                const text = await file.text();
                const result = parse(text, { header: true });
                setMyfile(result.data);
                setTextArea(true);
                fileUploader(e);
              });
            }}
          >
            <input
              ref={text}
              type="file"
              id="file-upload"
              className="d-none "
              onChange={(e) => {
                setMyfile(e.target.value);
                secondFileUploader(e);
                setTextArea(true);
              }}
            />
            {textarea ? (
              <textarea
                name=""
                id="myInput"
                cols=""
                rows=""
                className="text-area p-3"
                value={myfile}
                onChange={(e) => setMyfile(e.target.value)}
              ></textarea>
            ) : (
              <label htmlFor="file-upload">
                <p className="dragAndDropText text-center">
                  Drag & drop the CSV <br /> .. or click to select a file from
                  your computer
                </p>
              </label>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <div className="download-sample py-3 py-sm-0">
            Download sample{" "}
            <CSVLink {...csvReport}>
              <button className="file-type" disabled={!textarea && true}>
                CSV
              </button>
            </CSVLink>
            /{" "}
            <button
              className="file-type"
              disabled={!textarea && true}
              onClick={downloadTxtFile}
            >
              TXT{" "}
            </button>
          </div>
          {textarea ? (
            <span
              className="insert-manualy "
              onClick={() => setTextArea(false)}
            >
              Upload file
            </span>
          ) : (
            <span className="insert-manualy " onClick={() => setTextArea(true)}>
              Insert manually
            </span>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center approve-container">
          <Link to="/sendtoken">
            {" "}
            <button className="approvedButton ">Approve & Send</button>
          </Link>
        </div>
      </Col>
    </Wrapper>
  );
};
export default ApprovedAndSend;
