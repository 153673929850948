import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import Web3 from 'web3';
import { FileDrop } from 'react-file-drop';
import $ from 'jquery';
import _ from 'lodash';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { BigNumber } from "bignumber.js";
import { ALLOWED_CHAINID, API_URL, CHARGES, LIST_ABI_ERC20 } from "../config";
import Loading from "../Components/Loading/Loading";

const Wrapper = styled.div`
  padding-top: 110px;

  .hero-button {
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 6px 20px;
  }
  .hero-subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #fafafa;
  }
  .my-select {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;
    line-height: 22px;
    line-height: 100%;
    color: #36a693;
    position: relative;
  }
  .my-select-box {
    border-radius: 10px;
  }
  .dropdown-container {
    position: absolute;
    top: 35px;
    width: 100%;
    background: #060606;
    border-radius: 10px;
  }
  .dropdown-items:hover {
    background: var(--black);
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s;
  }

  .tokenAndDecimal-label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
  .tokenAndDecimal {
    background: var(--black);
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;

    color: var(--secondary-color);
    border-radius: 10px;
    color: #36a693;
    border: none;
    outline: none;
  }
  .dragAnddrop-container {
    height: 228px;

    border-radius: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    color: #fafafa;
  }
  .dragAndDropText {
    line-height: 22px;
    cursor: text;
  }
  .insert-manualy {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #36a693;
    display: block;
  }
  .approvedButton {
    border: none;
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;

    padding: 9px 22px;
  }

  .disableButton {
    border: none;
    background: linear-gradient(92.81deg,#6f968f -0.16%,#3e4c49 99.84%);
    border-radius: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #fafafa;
    padding: 9px 22px;
    cursor:pointer;
  }

  .disableConnectButton{
    border: none;
    background: linear-gradient(92.81deg,#31403d -0.16%,#171b1a 99.84%);
    border-radius: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #fafafa;
    padding: 9px 22px;
    cursor:pointer;
  }

  .download-sample {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #fafafa;
  }
  .file-type {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;

    color: #36a693;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
  }

  .duplicate-btn {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 16px;

    color: #36a693;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    padding: 10px 15px;
    font-weight: 700;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(25 25 25);
}

  .approve-container {
    padding-top: 50px;
  }
  .text-area {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    color: var(--subtitle-color);
    resize: none;
  }
  .file-upload {
    width: 100%;
    height: 100%;
  }

  .t-wrapper::before {
    text-transform: uppercase;
    left: 0px;
    position: absolute;
    top: -18px;
    font-size: 16px;
    height: 200px;
  }
  
  .my-num-active,
  .my-num-wrapper,
  .my-num-line {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    vertical-align: middle;
    list-style: none;
  }
  
  .my-num-active {
    display: inline-block;
    padding: 0.625em;
    width: calc(100% - 3em);
    height: 100%;
    font-size: 1em;
    line-height: 1.5;
    font-family: "Roboto Mono",monospace;
    word-break: break-all;
    border: 1px solid #319e8b;
    background-color: #060606;
    resize: none;
    overflow-wrap: normal;
    overflow-x: auto;
    white-space: pre;
    color: #fff;
  }
  
  .my-num-wrapper {
    width: 3em;
    padding: 0.6875em 0.3125em 2.1875em;
    height: 100%;
    word-break: break-all;
    overflow: hidden;
    display: inline-block;
    counter-reset: line;
  }
  
  .my-num-line {
    width: 100%;
    display: block;
    text-align: right;
    line-height: 1.5;
    font-size: 1em;
    color: #aeaeae;
  }
  
  .my-num-line::before {
    counter-increment: line;
    content: counter(line);
    font-size: 1em;
    user-select: none;
  }

  @media only screen and (max-width: 767px) {
    .approve-container {
      padding-top: 30px;
    }
  }
`;

const WrapperTokenBox = styled.div`
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 50px;
  .send-token-wrapper {
    background: var(--primary-color);
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;
  }

  .send-token-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    color: #36a693;
  }
  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .token-info-subtitle {
    min-height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .token-info-wrapper {
  }
  .token-info {
    background: var(--black);
    height: 100%;
    border-radius: 10px;
  }
  .button {
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 6px 20px;
  }
  .error {
    color: red;
    font-weight: 600;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 767px) {
  }
`;

const WrapperFinalLink = styled.div`
  min-height: 88vh;
  padding-top: 100px;
  padding-bottom: 50px;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;
  }
  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .succesfull-wrapper {
    border-radius: 10px;
  }

  .link-container {
    width: 100%;
    border-radius: 10px;
  }
  .link {
    color: #36a693;
  }
  .button {
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 4px 20px;
  }
  @media only screen and (max-width: 520px) {
    .title {
      font-size: 18px;
    }
  }
`;


export class ApproveSender extends React.Component {
  chargeValuePerTransfer = 0.02;
  batchCompleteLen = 0;
  batchCompleteList = [];

  constructor(props) {
    super(props);
    this.state = {
      web3: this.props.prop.web3,
      isEnabled: this.props.prop.isEnabled,
      chainId: this.props.prop.chainId,
      account: this.props.prop.account,
      ADDRESS_MAIN: this.props.prop.ADDRESS_MAIN,
      contractIns: this.props.prop.contractIns,
      networkType: this.props.prop.networkType,
      urlLink: this.props.prop.urlLink,
      ethUrl: this.props.prop.ethUrl,
      isConnecting: this.props.prop.isConnecting,
      coinType: this.props.prop.coinType,
      batchLength: this.props.prop.batchLength,

      currentScreen: 1,
      tokens: [],
      selectedTokenId: -1,
      selectedTokenDetail: null,
      batchLen: 0,
      csvData: null,
      csvArray: [],
      uploadTotal: 0,
      addresses: [],
      values: [],
      isPageOneBtnDisabled: true,
      isPageTwoBtnDisabled: true,
      isPageThreeBtnDisabled: true,
      isDirectPageThree: true,
      isViewChange: false,
      networkConnectionFor: ''
    }

    this.chargeValuePerTransfer = this.props.prop.coinType ? CHARGES[this.props.prop.coinType] : 0.02;
    this.fileInput = null;
    this.changeToken = this.changeToken.bind(this);
    this.pageOneNextClick = this.pageOneNextClick.bind(this);
    this.pageTwoNextClick = this.pageTwoNextClick.bind(this);
    this.pageThreeNextClick = this.pageThreeNextClick.bind(this);

    $('#OpenFileUpload').click(function () {
    });

    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 60000
    }
  }

  componentDidMount() {
    this.connectWallet()
  }

  componentWillReceiveProps(newProps) {
    this.setStateFromWithNewProps(newProps)
  }

  async setStateFromWithNewProps(props) {
    if (props) {
      await this.setState({
        web3: props.prop.web3,
        isEnabled: props.prop.isEnabled,
        chainId: props.prop.chainId,
        account: props.prop.account,
        ADDRESS_MAIN: props.prop.ADDRESS_MAIN,
        contractIns: props.prop.contractIns,
        networkType: props.prop.networkType,
        urlLink: props.prop.urlLink,
        ethUrl: props.prop.ethUrl,
        isConnecting: props.prop.isConnecting,
        coinType: props.prop.coinType,
        batchLength: props.prop.batchLength,
      })
      this.chargeValuePerTransfer = props.prop.coinType ? CHARGES[props.prop.coinType] : 0.02;
      this.connectWallet()
    }
  }

  async connectWallet() {
    if (this.state.isEnabled) {
      await this.getTokens();
    }
  }

  changeView(isViewChange) {
    this.setState({
      isPageOneBtnDisabled: true,
      batchLen: 0,
      csvData: null,
      csvArray: [],
      uploadTotal: 0,
      addresses: [],
      values: [],
      duplicateAddr: [],
      invalidRows: [],
      isViewChange: isViewChange
    }, this.canLoad(isViewChange))
  }

  canLoad(isViewChange) {
    if (isViewChange) {
      setTimeout(() => {
        this.append_line_numbers('textarea-num')
      })
    }
  }

  async setToDefaultData() {
    if (this.state.currentScreen == 1) {
      await this.setState({
        currentScreen: 1,
        tokens: [],
        selectedTokenId: -1,
        selectedTokenDetail: null,
        isPageOneBtnDisabled: true,
        isPageTwoBtnDisabled: true,
        isPageThreeBtnDisabled: true,
        isDirectPageThree: true,
      })
    } else {
      await this.setState({
        currentScreen: 1,
        tokens: [],
        selectedTokenId: -1,
        selectedTokenDetail: null,
        batchLen: 0,
        csvData: null,
        csvArray: [],
        uploadTotal: 0,
        addresses: [],
        values: [],
        isPageOneBtnDisabled: true,
        isPageTwoBtnDisabled: true,
        isPageThreeBtnDisabled: true,
        isDirectPageThree: true,
        isViewChange: false,
      })
    }
    if (this.state.isEnabled) {
      await this.connectWallet()
    }
  }

  getTokens = async () => {
    if (!this.state.account) return;
    if (ALLOWED_CHAINID[this.state.coinType].indexOf(this.state.chainId) == -1) return;

    let tokens = [];
    let url = "" + API_URL;
    url = url.replace('{CHAINID}', this.state.chainId);
    url = url.replace('{ACCOUNT}', this.state.account);
    await fetch(url)
      .then(res => res.json())
      .then(
        (resu) => {
          tokens = resu && resu['data'] && resu['data']['items'] ? resu['data']['items'] : [];
        },
        (error) => {
          tokens = [];
        }
      )
    this.setState({ tokens: tokens })
    // do something with myJson
  }

  handleFileUpload(files, event) {
    if (files.length == 1) {
      var re = /(\.txt|\.csv)$/i;
      if (!re.exec(files[0].name)) {
        toastr.error("File extension not supported! Please upload TXT or CSV file.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        let csvData = new TextDecoder("utf-8").decode(reader.result);
        // this.inputCSVManually = true;
        await this.setState({
          batchLen: 0,
          csvData: null,
          csvArray: [],
          uploadTotal: 0,
          addresses: [],
          values: [],
          isViewChange: true,
        });
        await this.onValidateInputs(csvData)
        this.append_line_numbers('textarea-num')
      };
      reader.readAsArrayBuffer(files[0]);

      this.fileInput.value = "";
    } else {
      toastr.error("Multiple file upload not possible.")
    }
  }

  csvDataToArrays(data) {
    return data.split("\n").map((v) => {
      if (v.includes('\r')) {
        v = v.replace(/(\r\n|\n|\r)/gm, '')
      }
      return v.split(",")
    });
  }

  async onValidateInputs(csvData) {

    if (!csvData) {
      this.setState({
        isPageOneBtnDisabled: true,
        batchLen: 0,
        csvData: csvData,
        csvArray: [],
        uploadTotal: 0,
        addresses: [],
        values: [],
        duplicateAddr: [],
        invalidRows: []
      })
    }
    if (!csvData) return false;

    let allValuesValid = null;
    let values = null;

    const csvArray = this.csvDataToArrays(csvData) || [];
    const addresses = csvArray.map((vals) => vals[0].trim());

    try {
      values = csvArray.map((vals) => {
        if (vals.length === 1) return "-1"; // No value has been supplied
        if (vals.length === 2 && !vals[1]) return "-1"; // Empty value supplied
        if (vals.length > 2) return "-1"; // Too many delimiters / values supplied
        // return ethers.utils.parseUnits(vals[1].trim(), this.decimals);
        return vals[1];
      });
      const invalidValues = values.filter(
        (value) => value.toString() === "-1"
      );
      allValuesValid = !invalidValues.length;
    } catch (e) {
      allValuesValid = false;
    }

    let allAddressesValid = addresses.every((addrToCheck) => {
      if (!addrToCheck) return false;
      try {
        let web3 = new Web3();
        return web3.utils.isAddress(addrToCheck);
      } catch (e) {
        return false;
      }
    });

    let valid = allAddressesValid && allValuesValid;

    let duplicateAddr = this.duplicateAddresses(csvArray);

    if (valid) {
      let totalValue = new BigNumber(0);
      for (let i = 0; i < values.length; i++) {
        totalValue = totalValue.plus(new BigNumber(values[i]));
      }
      totalValue = Number(totalValue);
      let batchLen = Math.ceil(values.length / this.state.batchLength);

      this.setState({
        isPageOneBtnDisabled: this.state.selectedTokenId != -1 ? false : true,
        batchLen: batchLen,
        csvData: csvData,
        csvArray: csvArray,
        uploadTotal: totalValue,
        addresses: addresses,
        values: values,
        duplicateAddr: duplicateAddr,
        invalidRows: []
      })
    } else {
      let invalidRows = this.invalidRows(csvArray);

      this.setState({
        isPageOneBtnDisabled: true,
        csvData: csvData,
        csvArray: csvArray,
        duplicateAddr: duplicateAddr,
        invalidRows: invalidRows
      });
    }
    // return allAddressesValid && allValuesValid;
  }

  async csvTextDataChange(event) {
    this.setState({ csvData: event.target.value })
    await this.onValidateInputs(event.target.value)
  }

  duplicateAddresses(csvArray) {
    const duplicateAddresses = [];
    const addressCount = {};
    this.csvDataFilteredForValidRowsOnly(csvArray).forEach((row, idx) => {
      const address = row[0];
      if (!addressCount[address]) {
        addressCount[address] = 1;
      } else if (addressCount[address] === 1) {
        duplicateAddresses.push(address);
        addressCount[address] = addressCount[address] + 1;
      }
    });

    return duplicateAddresses;
  }

  csvDataFilteredForValidRowsOnly(csvArray) {

    return csvArray.filter((row) => {
      // Row invalid if true
      if (row.length === 1) {
        return false;
      }

      if (row.length === 2 && !row[1]) {
        // Empty value supplied
        return false;
      }

      if (row.length > 2) return false;

      const address = row[0] && row[0].trim() ? row[0].trim() : null;
      if (!address) return false;
      try {
        let web3 = new Web3();
        return web3.utils.isAddress(address);
      } catch (e) {
        return false;
      }
    });
  }

  invalidRows(csvArray) {
    const validRows = [];
    const invalidRows = [];
    csvArray.forEach((row, idx) => {
      // Row invalid if true
      if (row.length === 1) {
        invalidRows.push([
          row[0] && row[0].trim() ? row[0].trim() : "Unknown",
          "Unknown",
          idx + 1,
        ]);
        return;
      }

      // Check address
      const address = row[0] && row[0].trim() ? row[0].trim() : null;
      if (!address) {
        invalidRows.push([...row, idx + 1]);
        return;
      }
      try {
        let web3 = new Web3();
        let isValid = web3.utils.isAddress(address);
        if (!isValid) {
          invalidRows.push([...row, idx + 1]);
        }
      } catch (e) {
        invalidRows.push([...row, idx + 1]);
        return;
      }

      //Validate value supplied
      if (row.length === 2 && !row[1]) {
        // Empty value supplied
        invalidRows.push([row[0], "Unknown", idx + 1]);
        return;
      }

      if (row.length > 2) {
        // Too many delimiters / values supplied
        invalidRows.push(["Unknown", "Unknown", idx + 1]);
        return;
      }
    });

    return invalidRows;
  }

  async changeToken(e) {
    if (!e || !(e && e['contract_ticker_symbol'])) {
      toastr.error("Please select valid token.")
      this.setState({ isPageOneBtnDisabled: true })
      return;
    }
    let selectedToken = {};
    let token = e['contract_ticker_symbol'];
    let temp = await this.state.web3.eth.getBalance(this.state.account);
    let userBalance = Number((Number(this.state.web3.utils.fromWei((temp).toString(), "ether"))).toFixed(2));
    let i = 0;

    for (let obj of this.state.tokens) {
      if (obj.contract_ticker_symbol == token) {
        selectedToken = obj;
        selectedToken['index'] = i;
        selectedToken['userBalance'] = userBalance;
        let tokenBalance = Number(selectedToken['balance']) / (this.getDecimalsNum(selectedToken['contract_decimals']));
        selectedToken['tokenBalance'] = tokenBalance.toFixed(2);
        break;
      }
      i = i + 1;
    }
    this.setState({
      isPageOneBtnDisabled: (this.state.csvArray && this.state.csvArray.length > 0) ? false : true,
      selectedTokenId: token,
      selectedTokenDetail: selectedToken
    })
  }

  getDecimalsNum(decimal) {
    let count = 1;
    for (let i = 0; i < decimal; i++) {
      count = count * 10;
    }
    return count;
  }

  getSelectOptionsToken() {
    if (this.state.tokens.length <= 0) {
      return;
    }
    return this.state.tokens.map((e, key) => {
      return <option key={key} value={e.contract_ticker_symbol}>{e.contract_name} ({e.contract_ticker_symbol})</option>;
    })
  }

  pageOneNextClick() {
    if (this.state.isPageOneBtnDisabled) {
      return;
    }
    if (this.state.selectedTokenDetail && this.state.selectedTokenId && this.state.csvArray && this.state.csvArray.length > 0) {
      if (this.state.selectedTokenDetail['contract_address'] && this.state.selectedTokenDetail['contract_ticker_symbol'] != this.state.coinType) {
        this.setState({ currentScreen: 0, isPageTwoBtnDisabled: false })
        this.allowance();
      } else if (!this.state.selectedTokenDetail['contract_address'] || this.state.selectedTokenDetail['contract_ticker_symbol'] == this.state.coinType) {
        this.setState({ currentScreen: 3, isPageThreeBtnDisabled: this.checkDataAtStageThree() })
      }
    } else {
      toastr.error("Something went wrong.")
    }
  }

  checkDataAtStageThree() {
    if (Number(this.state.selectedTokenDetail.userBalance) <= 0) {
      return true;
    }

    if ((this.state.uploadTotal) > Number(this.state.selectedTokenDetail.tokenBalance)) {
      return true;
    }

    // if ((this.state.uploadTotal + (100 * this.state.batchLen)) > Number(this.state.selectedTokenDetail.tokenBalance)) {
    //     return true;
    // }

    if (Number(this.state.selectedTokenDetail.userBalance) < (Number(this.chargeValuePerTransfer) + Number(0.0001))) {
      return true;
    }

    return false;
  }

  allowance() {
    try {
      if (this.state.isEnabled) {
        let web3_BINANCE_ERC20 = new this.state.web3.eth.Contract(LIST_ABI_ERC20, this.state.selectedTokenDetail.contract_address);
        web3_BINANCE_ERC20.methods.allowance(this.state.account, this.state.ADDRESS_MAIN).call().then(async (res) => {
          let temp = this.state.selectedTokenDetail;
          let decimal = (this.getDecimalsNum(this.state.selectedTokenDetail['contract_decimals']))

          temp['remaining'] = res;
          temp['remainingEth'] = (res / decimal).toFixed(0);
          let isTokenGretter = (this.state.selectedTokenDetail.tokenBalance >= this.state.uploadTotal)
          if (isTokenGretter && (Number(temp['remaining']) >= Number((this.state.uploadTotal * decimal).toFixed(0)))) {
            setTimeout(() => {
              this.setState({ currentScreen: 3, isPageThreeBtnDisabled: this.checkDataAtStageThree(), selectedTokenDetail: temp, isDirectPageThree: true });
            }, 1000)
          } else {
            setTimeout(() => {
              this.setState({ currentScreen: 2, selectedTokenDetail: temp, isPageTwoBtnDisabled: (isTokenGretter ? false : true), isDirectPageThree: false });
            }, 1000)
          }
        }, err => {
          toastr.error(err.message);
          this.setState({ currentScreen: 1 })
        })

      } else {
        toastr.info("You are not connected")
        this.setState({ currentScreen: 1 })
      }
    } catch (error) {
      toastr.error("Somthing went wrong. Please try again.")
      this.setState({ currentScreen: 1 })
    }
  }

  pageTwoNextClick() {
    if (this.state.isPageTwoBtnDisabled) {
      return;
    }
    this.setState({ currentScreen: 0 });
    this.approve();
  }

  async backClicked(screenNum) {
    if (screenNum == 2 && this.state.isDirectPageThree) {
      screenNum = 1;
    }
    await this.setState({ currentScreen: screenNum });
    this.checkScreen(screenNum)
  }

  checkScreen(screenNum) {
    if (screenNum == 1) {
      this.append_line_numbers('textarea-num')
    }
  }

  toPlainString(num) {
    return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      });
  }

  approve() {
    try {

      if (this.state.isEnabled) {
        let web3_BINANCE_ERC20 = new this.state.web3.eth.Contract(LIST_ABI_ERC20, this.state.selectedTokenDetail.contract_address);

        let decimal = new BigNumber(this.getDecimalsNum(this.state.selectedTokenDetail['contract_decimals']));
        let valueList = [].concat(this.state.values);
        let total = new BigNumber(0);
        for (let i = 0; i < valueList.length; i++) {
          total = total.plus((new BigNumber(valueList[i])).multipliedBy(decimal));
        }
        web3_BINANCE_ERC20.methods.approve(this.state.ADDRESS_MAIN, this.toPlainString(total)).send({
          from: this.state.account,
          value: 0
        }).then(async (res) => {
          setTimeout(() => {
            this.setState({ currentScreen: 3, isPageThreeBtnDisabled: this.checkDataAtStageThree() });
          }, 1000)
        }, err => {
          toastr.error(err.message);
          this.setState({ currentScreen: 2 })
        })

      } else {
        toastr.info("You are not connected.")
        this.setState({ currentScreen: 2 })
      }
    } catch (error) {
      toastr.error(error)
      this.setState({ currentScreen: 2 })
    }
  }

  pageThreeNextClick() {
    if (this.state.isPageThreeBtnDisabled) {
      return;
    }
    let currentScreen = this.state.currentScreen;
    this.setState({ currentScreen: 0 })
    if (this.state.selectedTokenDetail && this.state.selectedTokenId && this.state.csvArray && this.state.csvArray.length > 0) {
      if (this.state.selectedTokenDetail['contract_address'] && this.state.selectedTokenDetail['contract_ticker_symbol'] != this.state.coinType) {
        this.send(3);
      } else if (!this.state.selectedTokenDetail['contract_address'] || this.state.selectedTokenDetail['contract_ticker_symbol'] == this.state.coinType) {
        this.send(1)
      }
    } else {
      this.setState({ currentScreen: currentScreen });
    }
  }

  send(type) {
    try {
      if (this.state.isEnabled) {
        let tempDataAddr = [].concat(this.state.addresses);
        let tempDataValue = [].concat(this.state.values);
        for (let i = 0; i < this.state.batchLen; i++) {
          let addressList = tempDataAddr.splice(0, this.state.batchLength);
          let valueList = tempDataValue.splice(0, this.state.batchLength);
          let decimal18 = new BigNumber("1000000000000000000");
          const feeBN = (new BigNumber(this.chargeValuePerTransfer)).multipliedBy(decimal18);
          let decimal = new BigNumber(this.getDecimalsNum(this.state.selectedTokenDetail['contract_decimals']).toString());
          let total = new BigNumber(0);
          for (let i = 0; i < valueList.length; i++) {
            total = total.plus((new BigNumber(valueList[i])).multipliedBy(decimal));
            valueList[i] = (new BigNumber(valueList[i].toString())).multipliedBy(decimal);
            valueList[i] = "0x" + valueList[i].toString(16);
          }
          if (type == 1) {
            total = total.plus(feeBN);
            this.state.contractIns.methods.diistribute(addressList, valueList).send({
              from: this.state.account,
              value: total
            }).then(async (res) => {
              this.batchCompleteList.push(res);
              this.batchCompleteLen = this.batchCompleteLen + 1;
              if (this.state.batchLen == this.batchCompleteLen) {
                this.setState({ currentScreen: 4 });
              }
            }, err => {
              toastr.error(err.message);
              this.setState({ currentScreen: 3 })
            })
          } else if (type == 3) {
            this.state.contractIns.methods.distributeToken(this.state.selectedTokenDetail.contract_address, addressList, valueList).send({
              from: this.state.account,
              value: feeBN
            }).then(async (res) => {
              this.batchCompleteList.push(res);
              this.batchCompleteLen = this.batchCompleteLen + 1;
              if (this.state.batchLen == this.batchCompleteLen) {
                this.setState({ currentScreen: 4 });
              }
            }, err => {
              toastr.error(err.message);
              this.setState({ currentScreen: 3 })
            })
          }
        }

      } else {
        toastr.info("You are not connected.")
        this.setState({ currentScreen: 3 })
      }
    } catch (error) {
      toastr.error(error)
      this.setState({ currentScreen: 3 })
    }
  }

  pageFourNextClick() {
    // this.setState({ currentScreen: 1 });
    // window.location.reload();
    this.setToDefaultData();
  }

  getLinkList() {
    let link = this.state.urlLink + "/tx/";
    return this.batchCompleteList.map((e, index, key) => {
      return <div className="link-container d-flex justify-content-between aling-items-center px-3 py-2 my-5">
        <p className="m-0 subtitle">Transaction {index + 1} successful</p>
        <a href={link + e.transactionHash} target="_blank" >
          <p className=" m-0 link subtitle">Link</p>
        </a>
      </div>;
    })
  }

  scrollToReferralSystem() {
    var ele = document.getElementById("specials");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }

  downloadSample(type) {
    const rows = [
      ["0x67361A524Df98Af1dE26808E27d0E74A1F0b7a4F", "0.13"],
      ["0x8B14BEb458b885de64A16e5e9576729da38A28D0", "0.16"],
      ["0xcD43d0BD50B26F2E0064F77114DD0e5528c456d8", "0.99"],
      ["0xd360056DC45ab107d283c785ACdb87Efd4323646", "1.890"]
    ];

    let csvContent = type == 'csv' ? "data:text/csv;charset=utf-8," : "data:text/txt;charset=utf-8,";

    rows.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample." + type);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getInvalidRows() {
    return this.state.invalidRows.map((e, key) => {
      return (<tr >
        <td className="p-2">{e[0]}</td>
        <td className="p-2">{e[1]}</td>
        <td className="p-2">{e[e.length - 1]}</td>
      </tr>)
    })
  }

  getDuplicateAddr() {
    return this.state.duplicateAddr.map((e, key) => {
      return <tr>
        <td className="p-2">{e}</td>
      </tr>
    })
  }

  removeInvalidRows() {
    let csvData = this.csvArrayToString(
      this.csvDataFilteredForValidRowsOnly(this.state.csvArray)
    );
    this.onValidateInputs(csvData);
    setTimeout(() => {
      const ta = document.getElementById('textarea-num');
      const el = document.getElementById("text-nums");
      this.update_line_numbers(ta, el);
    }, 100)

  }

  csvArrayToString(csvArray) {
    return _.join(
      csvArray.map((row) => _.join(row, ",")),
      "\n"
    );
  }

  keepFirstAddressFromAddressDuplicates() {
    const firstAddressFound = {};
    const csvDataWithFirstDuplicateKept = this.csvDataFilteredForValidRowsOnly(this.state.csvArray).filter(
      (row) => {
        const address = row[0];
        let include = true;
        if (!firstAddressFound[address]) {
          firstAddressFound[address] = true;
        } else {
          include = !_.includes(this.duplicateAddresses(this.state.csvArray), row[0]);
        }

        return include;
      }
    );
    let csvData = this.csvArrayToString(csvDataWithFirstDuplicateKept);
    this.onValidateInputs(csvData);
    var ele = document.getElementById("upload");
    window.scrollTo(ele.offsetLeft, ele.offsetTop);
  }

  combineBalancesFromAddressDuplicates() {
    const totalBalances = {};
    this.csvDataFilteredForValidRowsOnly(this.state.csvArray).forEach((row) => {
      const address = row[0];
      const value = parseFloat(row[1]);

      if (!totalBalances[address]) {
        totalBalances[address] = value;
      } else {
        totalBalances[address] = totalBalances[address] + value;
      }
    });

    let csvData = this.csvArrayToString(
      Object.keys(totalBalances).map((address) => [
        address,
        totalBalances[address],
      ])
    );

    this.onValidateInputs(csvData);

  }

  eventList = {}

  update_line_numbers(ta, el) {
    // Let's check if there are more or less lines than before
    const line_count = ta.value.split("\n").length;
    const child_count = el.children.length;
    let difference = line_count - child_count;
    // If there is any positive difference, we need to add more line numbers
    if (difference > 0) {
      // Create a fragment to work with so we only have to update DOM once
      const frag = document.createDocumentFragment();
      // For each new line we need to add,
      while (difference > 0) {
        // Create a <span>, add class name, append to fragment and
        // update difference
        const line_number = document.createElement("span");
        line_number.className = "my-num-line";
        frag.appendChild(line_number);
        difference--;
      }
      // Append fragment (with <span> children) to our wrapper element
      el.appendChild(frag);
    }
    // If, however, there's negative difference, we need to remove line numbers
    while (difference < 0) {
      // Simple stuff, remove last child and update difference
      el.removeChild(el.lastChild);
      difference++;
    }
  }

  append_line_numbers(id) {
    // Get reference to desired <textarea>
    const ta = document.getElementById(id);
    // If getting reference to element fails, warn and leave
    if (ta == null) {
    }
    // If <textarea> already has  active, warn and leave
    if (ta.className.indexOf("my-num-active") != -1) {
      return;
    }
    // Otherwise, we're safe to add the class name and clear inline styles
    ta.classList.add("my-num-active");
    ta.style = {};

    // Create line numbers wrapper, insert it before <textarea>
    const el = document.createElement("div");
    el.className = "my-num-wrapper";
    el.id = "text-nums"
    ta.parentNode.insertBefore(el, ta);
    // Call update to actually insert line numbers to the wrapper
    this.update_line_numbers(ta, el);
    // Initialize event listeners list for this element ID, so we can remove
    // them later if needed
    this.eventList[id] = [];

    // Constant list of input event names so we can iterate
    const __change_evts = [
      "propertychange", "input", "keydown", "keyup"
    ];

    let that = this;
    // Default handler for input events
    const __change_hdlr = function (ta, el) {
      return function (e) {
        // If pressed key is Left Arrow (when cursor is on the first character),
        // or if it's Enter/Home, then we set horizontal scroll to 0
        // Check for .keyCode, .which, .code and .key, because the web is a mess
        // [Ref] stackoverflow.com/a/4471635/4824627
        if ((+ta.scrollLeft == 10 && (e.keyCode == 37 || e.which == 37
          || e.code == "ArrowLeft" || e.key == "ArrowLeft"))
          || e.keyCode == 36 || e.which == 36 || e.code == "Home" || e.key == "Home"
          || e.keyCode == 13 || e.which == 13 || e.code == "Enter" || e.key == "Enter"
          || e.code == "NumpadEnter")
          ta.scrollLeft = 0;
        // Whether we scrolled or not, let's check for any line count updates
        that.update_line_numbers(ta, el);
      }
    }(ta, el);

    // Finally, iterate through those event names, and add listeners to
    // <textarea> and to events list
    /// TODO: Performance gurus: is this suboptimal? Should we only add a few
    /// listeners? I feel the update method is optimal enough for this to not
    /// impact too much things.
    for (let i = __change_evts.length - 1; i >= 0; i--) {
      ta.addEventListener(__change_evts[i], __change_hdlr);
      this.eventList[id].push({
        evt: __change_evts[i],
        hdlr: __change_hdlr
      });
    }

    // Constant list of scroll event names so we can iterate
    const __scroll_evts = ["change", "mousewheel", "scroll"];
    // Default handler for scroll events (pretty self explanatory)
    const __scroll_hdlr = function (ta, el) {
      return function () { el.scrollTop = ta.scrollTop; }
    }(ta, el);
    // Just like before, iterate and add listeners to <textarea> and to list
    /// TODO: Also just like before: performance?
    for (let i = __scroll_evts.length - 1; i >= 0; i--) {
      ta.addEventListener(__scroll_evts[i], __scroll_hdlr);
      this.eventList[id].push({
        evt: __scroll_evts[i],
        hdlr: __scroll_hdlr
      });
    }
  }

  getErrorMessage() {
    if (!(Number(this.state.selectedTokenDetail.userBalance) > 0)) {
      return '* Insufficient ' + this.state.coinType + ' balance';
    } else if (Number(this.state.uploadTotal) > Number(this.state.selectedTokenDetail.tokenBalance)) {
      return '* Insufficient token balance';
      // } else if (Number(this.state.uploadTotal + (100 * this.state.batchLen)) > Number(this.state.selectedTokenDetail.tokenBalance)) {
      //     return '* Insufficient token balance';
    } else if (Number(this.state.selectedTokenDetail.userBalance) < (Number(this.chargeValuePerTransfer) + Number(0.001))) {
      return '* Insufficient ' + this.state.coinType + ' balance for fee';
    }
  }

  async setDropdown(open) {
    await this.setState({ dropdown: open })
  }

  getShortAccountId() {
    let address = "" + (this.state.account ? this.state.account : '');
    return address.slice(0, 8) + "....." + address.slice(address.length - 3, address.length);
  }

  onChangeType(type) {
    this.setState({ networkConnectionFor: type });
  }

  render() {
    return (
      <>
        {
          this.state.currentScreen == 1 ?
            <Wrapper>
              <Col
                xs={11}
                md={9}
                lg={8}
                xl={7}
                xxl={6}
                className="mx-auto hero-section-wrapper px-3 py-4"
              >
                {!this.state.isEnabled ? <p className="m-0 hero-subtitle text-center">
                  Login with your wallet to get started
                </p> : <p className="m-0 hero-subtitle text-center">
                  Connected wallet address
                </p>}
                {!this.state.isEnabled ? <div className="d-flex justify-content-center my-2">
                  <button className={this.state.networkConnectionFor == 'ETH' ? "hero-button" : 'disableButton'} onClick={() => { this.onChangeType('ETH') }}><img src="images/image-eth.png" /> Ethereum</button>
                  <button className={this.state.networkConnectionFor == 'BNB' ? "hero-button" : 'disableButton'} onClick={() => { this.onChangeType('BNB') }}><img src="images/bnb.png" /> Binance</button>
                </div> : ''}
                <div className="d-flex justify-content-center my-2">
                  {!this.state.isEnabled ?
                    <button className={this.state.networkConnectionFor ? "hero-button" : 'disableConnectButton'} onClick={() => { this.props.connectToMetaMaskNetwork(this.state.networkConnectionFor)}}>Connect wallet</button> :
                    <a className="hero-button" href={this.state.urlLink+'address/'+this.state.account} target="_blank">{this.getShortAccountId()}</a>
                  }
                </div>

                <Row>
                  <Col sm={9} md={9} lg={10}>
                    <div>
                      <label className="tokenAndDecimal-label px-2">Token</label>
                      <div className="my-select ">
                        <div
                          className="my-select-box d-flex justify-content-between align-items-center "
                          onClick={() => this.setDropdown(true)}
                        >
                          <p className="m-0 p-2 pt-3">
                            {this.state.selectedTokenDetail ? this.state.selectedTokenDetail['contract_name'] + ' (' + this.state.selectedTokenDetail['contract_ticker_symbol'] + ')' : "Select Token"}
                          </p>
                          {this.state.dropdown ? (
                            <BiChevronUp style={{ cursor: "pointer" }} />
                          ) : (
                            <BiChevronDown style={{ cursor: "pointer" }} />
                          )}
                        </div>
                        {this.state.dropdown && (
                          <div className="dropdown-container py-2">
                            {this.state.tokens.map((el, i) => (
                              <p
                                className="m-0 py-2  px-2 dropdown-items"
                                key={i}
                                onClick={() => {
                                  this.changeToken(el);
                                  this.setDropdown(false);
                                }}
                              >
                                {el.contract_name} ({el.contract_ticker_symbol})
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={3} md={3} lg={2}>
                    <div>
                      <label className="tokenAndDecimal-label px-2">Decimal</label>
                      <span className="tokenAndDecimal w-100 py-1 pt-2 px-2">{this.state.selectedTokenDetail && this.state.selectedTokenDetail.contract_decimals ? this.state.selectedTokenDetail.contract_decimals : 0}</span>
                    </div>
                  </Col>
                </Row>
                <div className="py-4">
                  <div
                    className="dragAnddrop-container d-flex justify-content-center align-items-center" id="upload"
                    onClick={() => { this.state.isViewChange ? $('#file_upload').trigger('focus') : $('#file_upload').trigger('click') }}
                  >
                    <input type="file" className="d-none " id="file_upload" ref={fileInput => (this.fileInput = fileInput)} style={{ display: 'none' }} onChange={(event) => { this.handleFileUpload(event.target.files, event) }} />
                    {this.state.isViewChange ? (
                      <div className="t-wrapper" style={{ height: '100%', width: '100%' }}>
                        <textarea
                          id="textarea-num"
                          className="banana-cake"
                          // id="myInput"
                          // className="text-area p-3"
                          value={this.state.csvData} onChange={(event) => { this.csvTextDataChange(event) }}
                        ></textarea>
                      </div>
                    ) : (

                      // <div className="tbx" style={{ height: '100%', textAlign: 'center' }} onClick={() => { $('#file_upload').trigger('click') }}>
                      <FileDrop
                        onDrop={(files, event) => this.handleFileUpload(files, event)}
                        style={{ height: '100' }}
                      >

                        <label>
                          <p className="dragAndDropText text-center">
                            Drag & drop the CSV <br /> .. or click to select a file from
                            your computer
                          </p>
                        </label>
                      </FileDrop>
                      // </div>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
                  <div className="download-sample py-3 py-sm-0">
                    Download sample{" "}
                    {/* <CSVLink {...csvReport}> */}
                    <button className="file-type"
                      onClick={() => { this.downloadSample('csv') }}>
                      CSV
                    </button>
                    {/* </CSVLink> */}
                    /{" "}
                    <button
                      className="file-type"
                      onClick={() => { this.downloadSample('txt') }}
                    >
                      TXT{" "}
                    </button>
                  </div>
                  {this.state.isViewChange ? (
                    <span
                      className="insert-manualy "
                      onClick={() => this.changeView(false)}
                    >
                      Upload file
                    </span>
                  ) : (
                    <span className="insert-manualy " onClick={() => this.changeView(true)}>
                      Insert manually
                    </span>
                  )}
                </div>
                {(this.state.invalidRows && this.state.invalidRows.length > 0) ?
                  (<div className="col-md-12">
                    <div className="my-2 alert alert-danger" style={{ backgroundColor: '#060606', borderColor: '#206b5e' }}>
                      <div>
                        The following rows looks like invalid:
                      </div>
                      <table className="table-striped my-3" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th className="p-2">Address</th>
                            <th className="p-2">Value</th>
                            <th className="p-2">Line</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.getInvalidRows()}
                        </tbody>
                      </table>
                      <div>
                        <button className="approvedButton " onClick={() => { this.removeInvalidRows() }}>Delete all invalid</button>
                      </div>
                    </div>
                  </div>) : ''}
                {(this.state.duplicateAddr && this.state.duplicateAddr.length > 0) ? (<div className="col-md-12">
                  <div className="my-2 alert alert-danger" style={{ backgroundColor: '#060606', borderColor: '#206b5e' }}>
                    <div style={{ fontWeight: '700' }}>Duplicate addresses have been found:</div>
                    <table className="table-striped my-3" style={{ width: '100%' }}>
                      <tbody>
                        {this.getDuplicateAddr()}
                      </tbody>
                    </table>
                    <div style={{ textAlign: 'center' }}>
                      <button className="duplicate-btn" onClick={() => { this.keepFirstAddressFromAddressDuplicates() }}>Keep the first one</button>|
                      <button className="duplicate-btn" onClick={() => { this.combineBalancesFromAddressDuplicates() }}>Combine Balances</button>
                    </div>
                  </div>
                </div>) : ''}
                <div className="d-flex justify-content-center align-items-center approve-container">
                  {this.state.isPageOneBtnDisabled ?
                    (<button disabled={this.state.isPageOneBtnDisabled} className="disableButton ">Approve & Send</button>)
                    :
                    (<button disabled={this.state.isPageOneBtnDisabled} onClick={() => this.pageOneNextClick()} className="approvedButton ">Approve & Send</button>)
                  }
                </div>
              </Col>
            </Wrapper>
            :
            ''}

        {
          this.state.currentScreen == 2 ?
            <WrapperTokenBox>
              <Col
                xs={11}
                md={10}
                lg={8}
                xl={7}
                xxl={7}
                className="mx-auto send-token-wrapper px-3 px-sm-5 py-4"
              >
                <h3 className="title text-center">Approval</h3>
                <p className="m-0 subtitle text-center">
                  This will authorise the amount of tokens you wish to send - once you have completed this step you will be taken to the next step where you will send your tokens.
                </p>
                <Row>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.selectedTokenDetail.remainingEth}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Your current approval
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.uploadTotal.toLocaleString('fullwide', { useGrouping: false })}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Total Number of tokens to send
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.selectedTokenDetail.tokenBalance}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Your token balance
                      </p>
                    </div>
                  </Col>

                  <Col xs={11} sm={12} md={12} className="error">
                    {(this.state.selectedTokenDetail.tokenBalance >= this.state.uploadTotal) ? '' : '* Insufficient token balance'}
                  </Col>
                </Row>

                <Row className="d-flex  justify-content-center align-items-center">
                  <Col xs={6} sm={4}>
                    <button
                      className="button w-100 justify-content-center py-2 px-5 mx-2"
                      onClick={() => this.backClicked(1)}>
                      Back
                    </button>{" "}
                  </Col>
                  <Col xs={6} sm={8}>
                    <button
                      className={"button w-100 justify-content-center py-2 px-5 mx-2 " + (this.state.isPageTwoBtnDisabled ? "disableButton" : '')}
                      disabled={this.state.isPageTwoBtnDisabled} onClick={() => this.pageTwoNextClick()}
                    >
                      Next
                    </button>
                  </Col>
                </Row>
              </Col>
            </WrapperTokenBox>
            :
            ''}

        {
          this.state.currentScreen == 3 ?
            <WrapperTokenBox>
              <Col
                xs={11}
                md={10}
                lg={8}
                xl={7}
                xxl={7}
                className="mx-auto send-token-wrapper px-3 px-sm-5 py-4"
              >
                <h3 className="title text-center">Send tokens</h3>
                <p className="m-0 subtitle text-center">
                  Check your token send information below, once happy all the
                  information is correct, click send to confirm your transactions.
                </p>
                <Row>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.csvArray ? this.state.csvArray.length : 0}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Total Number of Addresses
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.uploadTotal}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Total Number of tokens to send
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.selectedTokenDetail.tokenBalance}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Your token balance
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.batchLen}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Total Number of transactions needed
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.selectedTokenDetail.userBalance}</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Your {this.state.coinType} balance
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={11}
                    sm={6}
                    md={4}
                    className="my-2 p-2 token-info-wrapper mx-auto"
                  >
                    <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                      <p className=" send-token-title m-0">{this.state.batchLen * this.chargeValuePerTransfer} {this.state.coinType} + network fees</p>
                      <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                        Cost of operation
                      </p>
                    </div>
                  </Col>

                  <Col xs={11} sm={12} md={12} className="error">
                    {this.getErrorMessage()}
                  </Col>
                </Row>

                <Row className="d-flex  justify-content-center align-items-center">
                  <Col xs={6} sm={4}>
                    <button
                      className="button w-100 justify-content-center py-2 px-5 mx-2"
                      onClick={() => this.backClicked(2)}>
                      Back
                    </button>{" "}
                  </Col>
                  <Col xs={6} sm={8}>
                    <button
                      className={"button w-100 justify-content-center py-2 px-5 mx-2" + (this.state.isPageTwoBtnDisabled ? "disableButton" : '')}
                      disabled={this.state.isPageThreeBtnDisabled} onClick={() => this.pageThreeNextClick()}>
                      Next</button>
                  </Col>
                </Row>
              </Col>
            </WrapperTokenBox>
            :
            ''}
        {this.state.currentScreen == 4 ?
          <WrapperFinalLink>
            <Col
              xs={11}
              md={10}
              lg={8}
              xl={7}
              xxl={7}
              className="mx-auto succesfull-wrapper  px-3 px-sm-5 py-5  d-flex justify-content-center align-items-center flex-column"
            >
              <h3 className="title succesfull-title text-center">
                Congratulations! Distribution complete.
              </h3>
              <p className="m-0 subtitle text-center">
                View your transactions on the blockchain explorer
              </p>
              <div className="link-container d-flex justify-content-between aling-items-center px-3 py-2 my-5">
                <a href="#/">
                  <p className=" m-0 link subtitle">Link</p>
                </a>
              </div>
              {this.getLinkList()}
              <button
                className="button w-100 d-flex justify-content-center mt-3 px-5"
                onClick={() => this.pageFourNextClick()}
              >
                Retrun To Home
              </button>
            </Col>
          </WrapperFinalLink>
          :
          ''}
        {this.state.currentScreen == 0 ?
          <Loading></Loading>
          :
          ''}
      </>
    )
  }
}