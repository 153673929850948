import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
const Wrapper = styled.div`
  min-height: 88vh;
  padding-top: 100px;
  padding-bottom: 50px;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;
  }
  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .succesfull-wrapper {
    border-radius: 10px;
  }

  .link-container {
    width: 100%;
    border-radius: 10px;
  }
  .link {
    color: #36a693;
  }
  .button {
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 4px 20px;
  }
  @media only screen and (max-width: 520px) {
    .title {
      font-size: 18px;
    }
  }
`;

const SuccessfullPage = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Wrapper>
      <Col
        xs={11}
        md={10}
        lg={8}
        xl={7}
        xxl={7}
        className="mx-auto succesfull-wrapper  px-3 px-sm-5 py-5  d-flex justify-content-center align-items-center flex-column"
      >
        <h3 className="title succesfull-title text-center">
          Congratulations! Distribution complete.
        </h3>
        <p className="m-0 subtitle text-center">
          View your transactions on the blockchain explorer
        </p>
        <div className="link-container d-flex justify-content-between aling-items-center px-3 py-2 my-5">
          <p className="m-0 subtitle">Transaction 1 successful</p>
          <a href="#/">
            <p className=" m-0 link subtitle">Link</p>
          </a>
        </div>
        <button
          className="button w-100 d-flex justify-content-center mt-3 px-5"
          onClick={() => navigate("/")}
        >
          Retrun To Home
        </button>
      </Col>
    </Wrapper>
  );
};
export default SuccessfullPage;
