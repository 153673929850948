import React from "react";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Web3 from 'web3';
import { BscConnector } from '@binance-chain/bsc-connector';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { ADDRESS_BSC_MAINNET, ADDRESS_BSC_TESTNET, ADDRESS_ETH_MAINNET, ADDRESS_ETH_TESTNET, ALLOWED_CHAINID, BATCH_SIZE_BNB, BATCH_SIZE_ETH, LIST_ABI_MAIN, NETWORK_SUPPORT, RPC_INFO } from './config';

import Footer from "./Components/Footer/Footer";
import ApprovedAndSend from "./Components/ApprovedAndSend/ApprovedAndSend";
import SendToken from "./Pages/SendToken";
import SuccessfullPage from "./Pages/SuccesfullPage";
import Navbar from "./Components/Header/Header";
import { ApproveSender } from "./Pages/ApproveSender";

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      web3: null,
      isEnabled: false,
      chainId: null,
      account: null,
      ADDRESS_MAIN: null,
      contractIns: null,
      currentPath: window.location.pathname,
      networkType: '',
      coinType: 'ETH',
      batchLength: 1,
      urlLink: 'https://etherscan.com/',
      isConnecting: false,
      isSubWallet: false
    }
    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 60000
    }
  }

  componentDidMount() {
  }

  async showSubWallet() {
    this.setState({ isSubWallet: true })
  }

  async connectToMetaMaskNetwork(networkType) {
    if (!networkType) {
      return
    }
    if (this.state.isConnecting) {
      return;
    }
    this.setState({ isConnecting: true, coinType: networkType });
    if (window.ethereum) {
      await window.ethereum.enable().then((err, res) => {
      });
      this.state.web3 = new Web3(window.ethereum);
      window.ethereum.on('networkChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.ethereum.on('chainChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.ethereum.on('accountsChanged', chainId => {
        // handle the new network
        document.location.reload();
      })
      window.ethereum.on("disconnect", chainId => {
        document.location.reload();
      });
      try {
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        await this.setState({ isEnabled: false });
      }

    } else if (window.web3 && window.web3.currentProvider) {
      window.web3.on('networkChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.web3.on('chainChanged', chainId => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      })
      window.web3.on('accountsChanged', chainId => {
        // handle the new network
        document.location.reload();
      })
      window.web3.on("disconnect", chainId => {
        document.location.reload();
      });
      this.state.web3 = new Web3(window.web3.currentProvider);
      try {
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        await this.setState({ isEnabled: false, isConnecting: false });
      }
    } else {
      await this.setState({ isEnabled: false, isConnecting: false });
    }
  }

  async connectToBinanceWallet() {
    if (window.BinanceChain) {
      if (this.state.isConnecting) {
        return;
      }
      this.setState({ isConnecting: true });
      const bsc = new BscConnector({
        supportedChainIds: [56, 97] // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
      })
      await bsc.activate();
      if (window.BinanceChain.isConnected()) {
        window.BinanceChain.on('networkChanged', chainId => {
          // handle the new network
          // document.location.reload();
          this.setConfig();
        })
        window.BinanceChain.on('chainChanged', chainId => {
          // handle the new network
          // document.location.reload();
          this.setConfig();
        })
        window.BinanceChain.on('accountsChanged', chainId => {
          // handle the new network
          document.location.reload();
        })
        window.BinanceChain.on("disconnect", (code, reason) => {
          document.location.reload();
        });

        this.state.web3 = new Web3(window.BinanceChain);
        try {
          this.setConfig();
        } catch (e) {
          // User has denied account access to DApp...
          await this.setState({ isEnabled: false, isConnecting: false });
        }
      }
    }
  }

  async setConfig() {
    const Id = await this.state.web3.eth.net.getNetworkType();
    const chainId = await this.state.web3.eth.getChainId();
    const accounts = await this.state.web3.eth.getAccounts();
    this.state.web3.eth.defaultAccount = accounts[0];
    this.configNetwork(Id, chainId, accounts[0]);
  }

  async connectWallet() {
    if (chainId == 56) {
      this.connectToMetaMaskNetwork("BNB");
    } else {
      this.connectToMetaMaskNetwork("ETH");
    }
  }

  async switchNetwork(chainId) {
    try {
      let chain = '0x' + (chainId).toString(16);
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }],
      });
      await this.connectWallet();
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain,
                chainName: RPC_INFO[chainId].chainName,
                rpcUrls: RPC_INFO[chainId].rpcUrls,
                nativeCurrency: {
                  name: RPC_INFO[chainId].name,
                  symbol: RPC_INFO[chainId].symbol,
                  decimals: 18,
                },
                blockExplorerUrls: RPC_INFO[chainId].blockExplorerUrls,
              },
            ],
          });
          await this.connectWallet();
        } catch (error) {
          alert(error.message);
        }
      }
    }
  }

  async configNetwork(Id, chainId, account) {
    if (ALLOWED_CHAINID[this.state.coinType].indexOf(chainId) == -1) {
      if (this.state.coinType == "BNB") {
        await this.switchNetwork(56);
      } else {
        // this.switchNetwork(1);
        toastr.info(`This app currently works only in ${NETWORK_SUPPORT} network`);
      }
      await this.setState({ isEnabled: false, isConnecting: false });
      return;
    } else if (ALLOWED_CHAINID[this.state.coinType].indexOf(chainId) >= 0) {
      let contAddrMain = null;
      let coinType = 'ETH';
      let batchLength = 1;
      let urlLink = "https://etherscan.com/";
      if (chainId === 4) {
        coinType = 'ETH';
        batchLength = BATCH_SIZE_ETH;
        Id = "ETH testnet";
        contAddrMain = ADDRESS_ETH_TESTNET;
        urlLink = 'https://testnet.etherscan.com/';
      } else if (chainId === 1) {
        coinType = 'ETH';
        batchLength = BATCH_SIZE_ETH;
        Id = "ETH mainnet";
        contAddrMain = ADDRESS_ETH_MAINNET;
        urlLink = 'https://etherscan.com/';
      } else if (chainId === 97) {
        coinType = 'BNB';
        batchLength = BATCH_SIZE_BNB;
        Id = "BSC testnet";
        contAddrMain = ADDRESS_BSC_TESTNET;
        urlLink = 'https://testnet.bscscan.com/';
      } else if (chainId === 56) {
        coinType = 'BNB';
        batchLength = BATCH_SIZE_BNB;
        Id = "BSC mainnet";
        contAddrMain = ADDRESS_BSC_MAINNET;
        urlLink = 'https://bscscan.com/';
      }

      await this.setState({
        isEnabled: true,
        networkType: Id,
        chainId: chainId,
        account: account,
        coinType: coinType,
        batchLength: batchLength,
        urlLink: urlLink,
        ADDRESS_MAIN: contAddrMain,
        contractIns: new this.state.web3.eth.Contract(LIST_ABI_MAIN, contAddrMain)
      });
    }
  }

  render() {
    return (
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/approvedandsend" element={<ApprovedAndSend connectToMetaMaskNetwork={(type) => { this.connectToMetaMaskNetwork(type) }} connectToBinanceWallet={() => { this.connectToBinanceWallet() }} />}></Route>
          <Route path="/approveSender" element={<ApproveSender prop={this.state} connectToMetaMaskNetwork={(type) => { this.connectToMetaMaskNetwork(type) }} connectToBinanceWallet={() => { this.connectToBinanceWallet() }}></ApproveSender>}></Route>
          <Route path="/sendtoken" element={<SendToken />}></Route>
          <Route path="/sucessfull" element={<SuccessfullPage />}></Route>
        </Routes>
        <Footer />
      </Router>
    );
  };
}