import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
const Wrapper = styled.div`
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;

    color: #36a693;
    text-align: center;
  }
  .number-container {
    width: 37px;
  }
  .number {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 24px;
    /* or 50% */

    letter-spacing: 0.15px;
    text-transform: uppercase;

    color: #36a693;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #fafafa;
    padding-left: 25px;
  }
  @media only screen and (max-width: 991px) {
    .how-to-use-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
  @media only screen and (max-width: 520px) {
    .title {
      font-size: 25px;
    }
  }
`;

const HowToUse = () => {
  const howtouseArray = [
    "Select the Network you want to send tokens from (BSC or Ethereum)",
    "Prepare address list (CSV or TXT file)",
    "Make sure your wallet is on Main net",
    "Make sure you are connected to the correct account and Select the token you wish to send from the drop-down menu",
    "Click upload CSV and select your airdrop list (format must be addressed then balance separated by a comma and in the same column)",
    "Click Approve (This will prompt transactions in your wallet to approve the amount you want to send)",
    "Check the airdrop summary",
    "Click send",
    "Receive TXID",
  ];
  return (
    <Wrapper>
      <Col
        xs={11}
        md={9}
        lg={8}
        xl={7}
        xxl={6}
        className="mx-auto how-to-use-container"
      >
        <div className="title">How to use the Dbooks distribution tool?</div>
        <div className="py-5">
          {howtouseArray.map((el, i) => (
            <div className="step-container d-flex  align-items-center py-3">
              <span
                className="number"
                style={{ marginRight: i === 0 && "9px" }}
              >
                {i + 1}
              </span>

              <p
                className="text m-0"
                style={{ paddingLeft: i === 3 && "20px" }}
              >
                {el}
              </p>
            </div>
          ))}
        </div>
      </Col>
    </Wrapper>
  );
};

export default HowToUse;
