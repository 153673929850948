import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
const Wrapper = styled.div`
  padding: 25px 0;
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    text-align: center;
  }
  .privacy {
    color: #36a693;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Col
        xs={11}
        className="mx-auto d-flex justify-content-center align-items-center"
      >
        <p className="m-0 text">
          © Copyright dbooks 2022. All Rights Reserved |{" "}
          <span className="privacy">Privacy Policy</span> |{" "}
          <span className="privacy">Blog</span> |{" "}
          <span className="privacy">Homepage</span>
        </p>
      </Col>
    </Wrapper>
  );
};

export default Footer;
