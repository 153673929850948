import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
const Wrapper = styled.div`
  padding-bottom: 50px;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height */

    color: #36a693;
  }
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    color: #ffffff;
    padding-left: 70px;
  }
  @media only screen and (max-width: 991px) {
    .text {
      padding-left: 45px;
    }
  }
  @media only screen and (max-width: 520px) {
    .title {
      font-size: 25px;
    }
    .text {
      font-size: 15px;
    }
  }
`;

const Fees = () => {
  return (
    <Wrapper>
      <Col xs={11} md={9} lg={8} xl={7} xxl={6} className="mx-auto">
        <h3 className="title">Fees</h3>
        <div className="text">
          Dbooks distribution tool is free to use for premium membership
          holders. You can also use the distribution tool with no membership for
          a fee of 0.035 ETH per 200 addresses on the Ethereum network and 0.02
          BNB per 30 addresses on Binance smart chain.
        </div>
      </Col>
    </Wrapper>
  );
};

export default Fees;
