import React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  background: #000;
  z-index: 1;

  .logo-container {
    -webkit-filter: drop-shadow(0px 3px 55px rgba(54, 166, 147, 1));
    filter: drop-shadow(21px 9px 10px rgba(54, 166, 147, 1));
  }
  .logo {
    width: 250px;
  }
  .button {
    border: none;
    outline: none;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.07),
        rgba(255, 255, 255, 0.07)
      ),
      #36a693;
    border-radius: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #efefef;
    padding: 9px 18px;
    filter: drop-shadow(4px 4px 21px rgba(54, 166, 147, 0.8));
  }
  .second-logo {
    width: 40px;
    margin-right: 15px;
  }
  .logo-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    background: -webkit-linear-gradient(45deg, #36a693, #1d6559);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 991px) {
    padding: 15px 0;
    .logo {
      width: 180px;
    }
  }
  @media only screen and (max-width: 520px) {
    .logo {
      width: 130px;
    }
    .second-logo {
      width: 30px;
      margin-right: 5px;
    }
    .logo-text {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 380px) {
    .button {
      font-size: 14px;
    }
    .logo {
      width: 120px;
    }
    .second-logo {
      width: 30px;
      margin-right: 2px;
    }
    .logo-text {
      font-size: 18px;
    }
  }
`;

const Header = ({ button }) => {
  return (
    <Wrapper>
      <Col
        xs={11}
        className="mx-auto d-flex justify-content-between align-items-center py-2"
      >
        <span className="logo-container">
          <img src="./images/logo.svg" alt="Logo" className="logo" />
        </span>

        {button ? (
          <Link to="/approveSender">
            {" "}
            <button className="button">Open App</button>
          </Link>
        ) : (
          <div className="d-flex align-items-center">
            <img
              src="./images/secondLogo.png"
              alt="#"
              className="second-logo"
            />
            <h2 className="logo-text m-0">Distribution</h2>
          </div>
        )}
      </Col>
    </Wrapper>
  );
};

export default Header;
