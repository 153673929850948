import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
const WrapperDiv = styled.div`
  min-height: 100vh;

  padding-bottom: 50px;
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;
  }
  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .loading-wrapper {
    border-radius: 10px;
    padding: 70px;
  }
  .logo {
    width: 40%;
    margin-top: 30px;
  }
  @media only screen and (max-width: 991px) {
    .logo {
      width: 50%;
    }
  }
`;

const Loading = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <WrapperDiv>
      <Col
        xs={11}
        md={10}
        lg={8}
        xl={7}
        xxl={7}
        className="mx-auto loading-wrapper  px-3 px-sm-5  d-flex justify-content-center align-items-center flex-column"
      >
        <h3 className="title py-4 text-center">Loading...</h3>
        <p className="subtitle m-0 text-center">
          This can take several minutes. Please be patient and do not close this
          page.
        </p>
        <img src="images/secondlogo.png" alt="#" className="logo" />
      </Col>
    </WrapperDiv>
  );
};
export default Loading;
