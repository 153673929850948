import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import Loading from "../Components/Loading/Loading";

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 50px;
  .send-token-wrapper {
    background: var(--primary-color);
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: #fafafa;
  }

  .send-token-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    color: #36a693;
  }
  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .token-info-subtitle {
    min-height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;

    text-align: center;
    color: #ffffff;
  }
  .token-info-wrapper {
  }
  .token-info {
    background: var(--black);
    height: 100%;
    border-radius: 10px;
  }
  .button {
    background: linear-gradient(92.81deg, #36a693 -0.16%, #1d6559 99.84%);
    border-radius: 15px;
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #ffffff;
    padding: 6px 20px;
  }
  @media only screen and (max-width: 767px) {
  }
`;

const SendToken = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const tokenArray = [
    { number: 2, text: "Total number of addresses" },
    { number: 2, text: "Total number of tokens to send" },
    { number: 2, text: "Your token balance" },
    { number: 2, text: "Total number of transactions needed" },
    { number: 2, text: "Your balance" },
    { number: 2, text: "Cost of operation" },
  ];
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Wrapper className="">
      {loading ? (
        <Loading />
      ) : (
        <Col
          xs={11}
          md={10}
          lg={8}
          xl={7}
          xxl={7}
          className="mx-auto send-token-wrapper px-3 px-sm-5 py-4"
        >
          <h3 className="title text-center">Send tokens</h3>
          <p className="m-0 subtitle text-center">
            Check your token send information below, once happy all the
            information is correct, click send to confirm your transactions.
          </p>
          <Row>
            {tokenArray.map((el, i) => (
              <Col
                xs={11}
                sm={6}
                md={4}
                className="my-2 p-2 token-info-wrapper mx-auto"
              >
                <div className="d-flex justify-content-center align-items-center flex-column token-info p-3">
                  <p className=" send-token-title m-0">{el.number}</p>
                  <p className="send-token-subtitle m-0 text-center token-info-subtitle">
                    {el.text}
                  </p>
                </div>
              </Col>
            ))}
          </Row>

          <Row className="d-flex  justify-content-center align-items-center">
            <Col xs={6} sm={4}>
              <button
                className="button w-100 justify-content-center py-2 px-5 mx-2"
                onClick={() => navigate("/")}
              >
                Back
              </button>{" "}
            </Col>
            <Col xs={6} sm={8}>
              <button
                className="button w-100 justify-content-center py-2 px-5 mx-2"
                onClick={() => setLoading(true)}
              >
                Next
              </button>
            </Col>
          </Row>
        </Col>
      )}
    </Wrapper>
  );
};
export default SendToken;
